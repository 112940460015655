const routes = {
  index: '/',
  auth: {
    login: {
      index: '/auth/sign-in',
      redirectTo: (redirectTo) => `/auth/sign-in?redirect_to=${encodeURIComponent(redirectTo)}`
    },
    signUp: {
      index: '/auth/sign-up',
      redirectTo: (redirectTo, isEmbedded) =>
        `/auth/sign-up?embedded=${isEmbedded ? 'true' : 'false'}&redirect_to=${encodeURIComponent(redirectTo)}`
    },
    confirmEmail: '/auth/verify-email',
    resetPassword: '/auth/reset-password',
    resetPasswordConfirm: '/auth/reset-password-confirm',
    changeEmailConfirm: '/auth/email-change-confirm',
    emailConfirm: '/auth/email-confirm'
  },
  messages: '/inbox',
  registrationComplete: '/registration-complete',
  destinations: {
    index: '/destinations',
    details: (slug) => `/${slug}`
  },
  destination: (destinationSlug) => `/${destinationSlug}`,
  trips: {
    my: '/my-trips',
    request: (id) => `/my-trips?request=${id}`,
    details: (id) => `/trip/details/${id}`,
    payment: (id) => `/trip/payment/${id}`,
    itinerary: (itineraryId) => `/trip/itinerary/${itineraryId}`,
    editTraveller: (tripId, travellerId) => `/trip/traveller/edit/${tripId}/${travellerId}`,
    createTraveller: (tripId) => `/trip/traveller/create/${tripId}`,
    cancel: (id) => `/trip/cancel/${id}`,
    createFlight: (tripId) => `/trip/flights/create/${tripId}`,
    editFlight: (tripId, flightId) => `/trip/flights/edit/${tripId}/${flightId}`
  },
  inbox: {
    index: '/inbox',
    chat: (id) => `/inbox?chat=${id}`
  },
  profile: {
    index: '/profile',
    edit: '/profile/edit'
  },
  accountPreferences: '/account-preferences',
  requestForm: {
    index: `/request-to-travel`,
    itineraryRequest: (id) => `/request-to-travel?itinerary_id=${id}`,
    templateRequest: (id) => `/request-to-travel?template_id=${id}`,
    tripRequest: (id) => `/request-to-travel?id=${id}`,
    matching: (id, destination, step) => `/request-to-travel/matching?id=${id}&destination=${destination}&step=${step}`,
    country: (country) => `/request-to-travel/${country}`
  },

  ourRevolution: '/our-revolution',
  howItWorks: '/#how-it-works',
  weAre: '/we-are',
  ourCommitments: '/our-commitments',
  smallGroupTrips: {
    index: '/small-group-trips',
    trip: (tripID) => `/small-group-trips/${tripID}`,
    requestSGTForm: {
      index: '/small-group-trips/request-to-travel',
      trip: (tripID) => `/small-group-trips/request-to-travel/?trip=${tripID}`
    }
  },
  contactUs: '/contact-us',
  privacyPolicy: '/privacy-policy',
  doNotSell: '/do-not-sell-or-share-my-personal-information',
  termsOfUse: '/terms-of-use',
  serverSitemap: '/server-sitemap.xml'
};

module.exports = routes;
