export const userTokenCookiesKey = 'app-token';
export const appDomain = process.env.NEXT_PUBLIC_APP_DOMAIN;
export const requestFormDomain = process.env.NEXT_PUBLIC_REQUEST_FORM_DOMAIN;
export const mapBoxApiKey = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;
export const stripeKey = process.env.NEXT_PUBLIC_STRIPE_KEY;
export const googleAnalyticsId = process.env.NEXT_PUBLIC_GANALYTICS;
export const googleTagManagerId = process.env.NEXT_PUBLIC_GTAG_ID;
export const makeWriteKey = process.env.NEXT_PUBLIC_MAKE_WRITE_KEY;
export const makeSourceKey = process.env.NEXT_PUBLIC_MAKE_SOURCE_KEY;
export const monarchToken = process.env.NEXT_PUBLIC_MONARCH_TOKEN;
export const monarchSourceId = process.env.NEXT_PUBLIC_MONARCH_SOURCE_ID;
export const preampSiteId = process.env.NEXT_PUBLIC_PREAMP_SITE_ID;
export const showTripStatusInList = process.env.NEXT_PUBLIC_SHOW_TRIP_STATUS_IN_LIST === 'true';
export const googlePlacesApiKey = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY;
export const sentryEnvironment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

export const cohestionDomainAllowList = [
  'request.elsewhere.io',
  'request.staging.elsewhere.io',
  'request.development.elsewhere.io'
];

export const dateFormats = {
  timer: 'mm:ss',
  serverDay: 'YYYY-MM-DD',
  serverDayUS: 'MM-DD-YYYY',
  profileCreated: 'MMMM DD, YYYY',
  tripDate: 'MMM DD, YYYY',
  birthDate: 'MMM DD YYYY',
  tripDateShort: 'MMM DD',
  flightDate: 'MMM DD YYYY, hh:mm A',
  messageDateFull: 'MMM DD YYYY, hh:mm A',
  messageDate: 'hh:mm A',
  messageSeparatorDate: 'MMM DD YYYY',
  agentTime: 'hh:mm A',
  fileCreated: 'MMM DD YYYY',
  fileCreatedGlobal: 'DD/MM/YYYY',
  paymentDate: 'MMM DD YYYY',
  flightTime: 'HH:mm',
  humanReadableZone: 'zzz'
};

export const socialAuth = {
  googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  facebookAppId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
};

export const nextStaticReValidate = 60;

export const insuranceProviderUrl = 'https://gowanderwell.com/affiliate/elsewhere';

export const localExpertAppURL =
  'https://docs.google.com/forms/d/e/1FAIpQLSevo_Gw9RLsxk1lArS7OWvkPHhtHZymWf_JhPYPMT7MWrsHNQ/viewform?usp=sf_link';

export const tripStatuses = {
  pre_request: 'awaiting_verification',
  new_request: 'new_request',
  crafting: 'crafting',
  deposit: 'deposit',
  fully_paid: 'fully_paid',
  on_trip: 'on_trip',
  completed: 'completed',
  cancelled: 'cancelled',
  on_hold: 'on_hold'
};

export const tripStatusesSimplified = {
  crafting: 'crafting',
  booked: 'booked',
  completed: 'completed',
  cancelled: 'canceled'
};

export const tripStatusesDict = {
  [tripStatusesSimplified.crafting]: [tripStatuses.pre_request, tripStatuses.new_request, tripStatuses.crafting],
  [tripStatusesSimplified.booked]: [tripStatuses.deposit, tripStatuses.fully_paid, tripStatuses.on_trip],
  [tripStatusesSimplified.completed]: [tripStatuses.completed],
  [tripStatusesSimplified.cancelled]: [tripStatuses.cancelled, tripStatuses.on_hold]
};

export const messageTypes = {
  regular: 'regular',
  system: 'system',
  file: 'file',
  crafting: 'crafting',
  welcome: 'welcome'
};

export const defaultCurrency = '$';
export const defaultCurrencyCode = 'USD';

export const destinationStatuses = {
  active: 'active',
  inactive: 'inactive',
  demand_list: 'demand_list'
};

export const insuranceOptions = {
  peace_of_mind: 'peace_of_mind',
  live_on_the_edge: 'live_on_the_edge'
};

export const brandName = 'Elsewhere';

export const fetchShortProfileInterval = 15 * 1000; // 15sec

export const customEvents = {
  countersPollingTick: 'counters-polling-tick'
};

export const craftingFee = 100;

export const adjustmentPerSoloTraveler = 1.75;

export const localExpertData = {
  experts: [
    {
      name: 'Denise & Rob',
      location: 'Tanzania',
      experienceDescription:
        'A traditional marriage blessing for a honeymoon couple with the Maasai. They stood in a sacred space while the village elders blessed them and celebrated this next step in their life.',
      image: '/local-experts/denise.jpg',
      video: '/local-experts/videos/denise.mp4'
    },
    {
      name: 'Zoloo',
      location: 'Mongolia',
      experienceDescription:
        'Tracking the endangered Gobi bear during a two week conservation expedition for The Gobi Bear project, a non-profit dedicated to saving the critically endangered animal.',
      image: '/local-experts/zoloo.jpg',
      video: '/local-experts/videos/zoloo.mp4'
    },
    {
      name: 'Lucas',
      location: 'Argentina',
      experienceDescription:
        "I tracked down a traveler's favorite artist, got them to meet my client and paint a custom piece on a pair of new sneakers, which we helped ship home. It was the ultimate souvenir!",
      image: '/local-experts/lucas.jpg',
      video: '/local-experts/videos/lucas.mp4'
    },
    {
      name: 'June',
      location: 'Thailand',
      experienceDescription: 'An intricate secret proposal - the details are too good to share, but she said yes!',
      image: '/local-experts/june.jpg',
      video: '/local-experts/videos/june.mp4'
    },
    {
      name: 'Pravin',
      location: 'India',
      experienceDescription:
        'Holi in Udaipur with a local family in their 250-year-old heritage house. It was an all-day party with friends and relatives, a big feast, and lots of color, and they were welcomed like family.',
      image: '/local-experts/pravin.jpg',
      video: '/local-experts/videos/pravin.mp4'
    },
    {
      name: 'Gunnar',
      location: 'Iceland',
      experienceDescription:
        'Catching a northern lights show while bathing in a geothermal hot spring. There are no words to describe the magic.',
      image: '/local-experts/gunnar.jpg',
      video: '/local-experts/videos/gunnar.mp4'
    },
    {
      name: 'Andrea',
      location: 'Italy',
      experienceDescription:
        'Truffle hunting with Massimo, a third-generation expert tartufai, and his truffle dog, followed by a homecooked meal and local wine with his family.',
      image: '/local-experts/andrea.jpg',
      video: '/local-experts/videos/andrea.mp4'
    },
    {
      name: 'Cris',
      location: 'Brazil',
      experienceDescription:
        "Pulled strings to get my client an invite to dance and parade, in full costume, in the Sambadrome for Carnival, with Rio's oldest samba school.",
      image: '/local-experts/cris.jpg',
      video: '/local-experts/videos/cris.mp4'
    },
    {
      name: 'Tiago',
      location: 'Portugal',
      experienceDescription:
        "I traced a traveler's history to their grandparents' hometown where I found an elderly couple who not only knew her grandparents, but brought us to the church where they married.",
      image: '/local-experts/tiago.jpg',
      video: '/local-experts/videos/tiago.mp4'
    },
    {
      name: 'Thanh',
      location: 'Vietnam',
      experienceDescription:
        "Zipping through Saigon on the back of a vintage Vespa, sampling the city's best cuisine at local haunts and street stalls.",
      image: '/local-experts/thanh.jpg',
      video: '/local-experts/videos/thanh.mp4'
    },
    {
      name: 'Venese',
      location: 'Japan',
      experienceDescription:
        'Making a traditional Japanese knife with artisans who have been mastering their craft for decades. It was the perfect souvenir for my chef client.  ',
      image: '/local-experts/venese.jpg',
      video: '/local-experts/videos/venese.mp4'
    }
  ]
};

export const mockGoogleData = {
  rating: 4.9,
  totalReviews: 269,
  reviewsLink:
    'https://www.google.com/search?hl=en-US&gl=us&q=Elsewhere,+100+5th+Ave+16th+Floor+16th+Floor,+New+York,+NY+10011&ludocid=12108243362783337842&lsig=AB86z5XcEHOAew0ZAURExatqumqw&mat=Cd-hfE7kvHQKElYBEKoLaQmnODF_UzFeioo7rGx3HNlRwwdJoVzZ9HO7-5rIj_Nztdi8iNHEK3IyfVSZY6PcJXCZTqL1bjvRBhq2BCNo6JzxjW6iOEUenA4692FGFpEuaA&safe=active&ssui=on#lrd=0x88fb715aedad8263:0xa8091f2facae9d72,1',
  reviews: [
    {
      text: 'Boy do they make magic happen. Top highlight was riding on horseback with a locally-renowed champion eagle hunter and their nomadic family through the Altai mountains while they practiced their call-and-release eagle training.',
      rating: 5,
      author: 'Rachel',
      source: 'Mongolia'
    },
    {
      text: "I am a lifetime explorer. I've had an active passport since I was 5. And I may not ever travel again without using Elsewhere.",
      rating: 5,
      author: 'Belinda',
      source: 'Ghana'
    },
    {
      text: 'My husband and I are avid travelers. It takes so much time to research, research and research. We love it but it’s exhausting. For our anniversary, we wanted to have an epic trip. We decided to try Elsewhere and it was a complete game changer for us.',
      rating: 5,
      author: 'Caroline',
      source: 'Egypt'
    },
    {
      text: "I highly recommend Elsewhere regardless of how savvy a traveler you are. I'm a retired airline pilot with 50 years of travel under my belt and I saw, tasted, and heard things I would never have gotten to on my own.",
      rating: 5,
      author: 'Bill',
      source: 'Italy'
    },
    {
      text: 'Nothing was ever out of the question for these professionals. I will never try to plan a vacation on my own again.',
      rating: 5,
      author: 'Pam',
      source: 'Croatia'
    },
    {
      text: "Hiking the sacred Dewa Sanza Mountains, meeting the Yamabushi monks and learning to forage and cook a traditional Shojin Ryori dish, I can't emphasize how spectacular and enlightening this experience was.",
      rating: 5,
      author: 'Emma',
      source: 'Japan'
    },
    {
      text: 'From the initail consultation to the final goodbye, our experience was nothing short of outstanding. Elsewhere demonstrated professionalism, expertise, and a genuine commitement to ensuring our trip exceeded expectations. Their attention to detail was absolutely remarkable.',
      rating: 5,
      author: 'Jameela',
      source: 'Spain'
    },
    {
      text: 'Best travel decision ever! Elsewhere curated a phenomenal trip, never have I been so blown away before.',
      rating: 5,
      author: 'Rachel',
      source: 'Armenia'
    },
    {
      text: 'When I stumbled onto Elsewhere, I thought I would just be getting access to a generic itinerary template that I could then tailor on my own, but wow was I wrong!',
      rating: 5,
      author: 'Jeanette',
      source: 'Japan'
    },
    {
      text: "The trip was truly TRANSFORMATIVE for me on so many levels. It deposited so much more than I ever thought possible. I don't know that I will ever find words to capture the spiritual experiences we had there.",
      rating: 5,
      author: 'Elaine',
      source: 'South Africa'
    },
    {
      text: "Wow! I don't even know where to begin... When they say you have 24 hr concierge services, they aren't joking. I've never been so blown away by customer service this outstanding.",
      rating: 5,
      author: 'Caroline',
      source: 'Egypt'
    },
    {
      text: "It's the world's greatest understatement to say that Andrea absolutely, 1000%, nailed it. Could we have planned a honeymoon like this? It would've taken us probably 40-50 hours to do all the research, but there was absolutely things we got to do that we wouldn't have thought of or had access to.",
      rating: 5,
      author: 'Dan',
      source: 'Italy'
    },
    {
      text: 'I had the extraordinary opportunity to hike to Annapuma Base Camp, From start to finish, the experience was spectacular.',
      rating: 5,
      author: 'Matthew',
      source: 'Nepal'
    },
    {
      text: 'Magical, beautiful, life changing, exceeded all expectations! From the sunset camel ride, to the jeep tour through Wadi Rum, the tours of Jaresh and Petra, snorkeling in the Red Sea, and soaking in the Dead Sea, we were stunned, suprised and overwhelmed.',
      rating: 5,
      author: 'Kristin',
      source: 'Jordan'
    },
    {
      text: "Connecting with a destination through people who know and love it intimately is the best way to experience it's people, food, culture and sights. I have memories that will last a lifetime. Thank you all!",
      rating: 5,
      author: 'Kimberly',
      source: 'Scotland'
    },
    {
      text: 'I have nothing but magic to share about our trip. Pravin is an absolute wizard and crafted the most unforgettable itinerary and experience for us.',
      rating: 5,
      author: 'Roohi',
      source: 'India'
    }
  ]
};
